@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "../../variables";

.loader {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
}
