@import "../../../variables";
.label-numbered {
    font-size: 18px;
    display: flex;
    align-items: center;


    .number {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        border: 1px solid #E1E1E1;
        background-color: white;
    }
    .text {
        margin-left: $gutter-small;
    }

}