@import "../../variables";

.badge {
    background-color: transparent;
    border: 1px solid gray;
    color: #5d5c5d;
    font-weight: normal;

    padding-left: $gutter-small;
    padding-right: $gutter-small;
    border-radius: $gutter-small;
    margin-right: $gutter-small;

    display: inline-flex;
    justify-content: space-between;

    svg {
        margin-right: 6px;
    }    

    &.closable {
        padding-right: 0px;

        .badge-value {
            margin-right: $gutter-small;
            max-width: 50px;
        }
    }

    &-icon {
        svg {
            margin-right: 6px;
            color: #f4bf26;
        }
    }

    &.md {
        font-size: 12px;
    }
}
