@import "../../variables";

.table {
  background-color: $background-dark;
  margin-bottom: 6px;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 12px 0 12px 0;
  border-radius: $standard-border-radius;
  font-size: 14px;
  color: #5d5c5d;

  &.edit {
    background-color: white;
  }



  tr {
    &:first-child {
      td {
        div {
          text-align: center;
          .icon {
            color: $weak-gray-color-1;
          }

        }

      }
    }

    td {

      vertical-align: middle;
    }

    &.selected {
      border: 1px solid red;
    }

    &.empty {
      font-weight: bold;
    }

    &.grey {
      color: grey;
    }

    &.green {
      background-color: rgba(28, 152, 72, 0.1);
    }

    &.red {
      background-color: rgba(219, 62, 49, 0.1);
    }
  }

  .header {
    display: flex;
    padding-left: 12px;
    padding-right: 12px;
    justify-content: flex-start;

    .name {
      display: flex;
      flex-grow: 1;
      font-size: 18px;
      margin-left: $gutter-small;
      margin-right: $gutter-small;

      .icon {
        margin-left: $gutter-small;
      }
    }

    .statistics {
      .important {
        margin-left: $gutter-small;
        margin-right: $gutter-small;
        font-weight: bold;
        font-size: 18px;
      }
    }

    .icon {
      display: flex;
      align-items: center;
    }
  }

  table {
    width: 100%;
    margin-top: 12px;
  }
}

.popup-overlay {
  background-color: $opaque-background;

}

.properties-form {
  &-content {
    width: 200px;
  }

  &-body {

    .btn {
      width: 100%;

    }
  }
}