@import "../../variables";

.table-form {
  margin-bottom: 6px;

  &-body {
    background-color: $background-dark;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 12px;
    border-radius: $standard-border-radius;
    font-size: 14px;
    color: #5d5c5d;

  }

  textarea {
    width: 100%;
    height: 300px;
    margin-bottom: 12px;
  }

  .name {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 12px;

    .icon {
      width: 20px;
      margin-left: 12px;
    }
  }

  .form-controlls {
    display: flex;
    justify-content: space-between;
  }

}