@import "../../variables";

.message-group {
    flex-grow: 1;
    &-table {
        display: flex;

        &-timeline {
            display: inline-flex;
            flex-direction: column;
            width: 70px;

            &-item {
                display: inline-flex;
                flex-direction: column;
                height: 48px;
                margin-bottom: 6px;

                .time {
                    font-size: $middle-font-size;
                    font-weight: normal;
                }
                .date {
                    font-size: $small-font-size;
                    color: $weak-gray-color-1;
                }
            }
        }

        &-messages {
            display: inline-flex;
            flex-direction: column;
            width: 100%;
            margin-left: $gutter-small;

            &-item {
                height: 48px;
                display: flex;
                align-items: center;

                background-color: $background-dark;
                margin-bottom: 6px;
                position: relative;
                display: flex;
                padding: 12px;
                border-radius: $standard-border-radius;
                font-size: 14px;
                color: #5d5c5d;

                .sender {
                    max-width: 100px;
                }

                &-snippet {
                    padding-left: $gutter-small;
                    padding-right: $gutter-small;
                    flex-grow: 1;
                    max-height: $gutter-medium;
                    overflow-y: hidden;
                }
            }
        }

        &.grouped {
            display: grid;
            grid-template-areas: "senders senders" "timeline messages";
            position: relative;

            .message-group-table-timeline {
                grid-area: timeline;
            }
            .message-group-table-messages {
                grid-area: messages;
            }
            .message-group-table-senders {
                grid-area: senders;
                display: grid;
                margin-left: 84px;
                top: 0;
                position: sticky;
                background-color: white;
                z-index: 900;
                &-sender {
                    color: $blue-active-2;
                    padding-top: $gutter-small;
                    padding-bottom: $gutter-small;
                    padding-right: $gutter-small;
                    padding-left: $gutter-small;
                }
            }
        }

    }
    &.active {
        border: 1px solid red;
    }
}
