@import "../../../variables";

.part {
    padding-left: $gutter-small;
    padding-right: $gutter-small;
    background-color: white;
    display: flex;
    flex-direction: column;

    &.no-gutter {
        padding-left: 0;
        padding-right: 0;
    }
}