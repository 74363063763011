$dark-gray-color : #282d35;
$light-gray-color : #585c62;

$weak-gray-color : #99a0a7;
$weak-gray-color-1 : #a1a8af;
$weak-gray-color-2: #f3f4f6;

$font-color-dark-grey : #172b4d;
$font-color-white : white;
$font-color-red : #ea373a;


$background-dark-gray : #22252a;
$background-light-gray : #eef2f5;
$background-white : #feffff;
$background-red : #ea373a;

$background-dark: #F8F8F8;

$blue-light: #DCE9FF;
$blue-active: #417ABB;
$blue-active-2: #2D82F2;

$big-font-size : 38px;
$middle-font-size : 18px;
$small-font-size : 14px;

$mobile-big-font-size : 38pt;
$mobile-header-font-size : 18pt;
$mobile-middle-font-size : 12pt;
$mobile-small-font-size : 6pt;

$standard-border-radius : 12px;
$half-border-radius : 6px;

$gutter-half : 6px;
$gutter-small : 12px;
$gutter-medium : 24px;

$shadow :  0 0 10px rgba(0, 0, 0, 0.1);
$opaque-background :rgba(0, 0, 0, 0.15);

$border : 1px solid #D1D1D1;


