@import "../../../variables";

.table-row {
    border-bottom-left-radius: $standard-border-radius;
    border-bottom-right-radius: $standard-border-radius;
    box-shadow: $shadow;
    background-color: white;
    padding: $gutter-small;
    display: flex;
    justify-content: space-between;
    z-index: 1000;
}