@import "../../variables";

.connection-form {
  color: $font-color-dark-grey;
  background-color: $background-dark;
  padding: 36px;
  border-radius: 12px;
  &-container {
    display: flex;

    justify-content: space-between;

    .texts {
      flex-grow: 1;
      display: flex;
      justify-content: space-evenly;
      div:first-child {
        flex-grow: 1;
      }
      div:last-child {
        flex-grow: 1;
      }

    }
  }
  .important {
    color: red;
  }
}
