@import "../../../variables";

.shared-table {
    &-data {
        table {
            width: 100%;
        }
        tr {
            background-color: #F8F8F8;
            border-bottom: 9px solid white;
            &.grey {
                color: grey;
            }
            &.green {
                background-color: rgba(28, 152, 72, 0.1);
            }
            &.red {
                background-color: rgba(219, 62, 49, 0.1);
            }
        }

        td {
            padding: 12px;
        }


    }
}
