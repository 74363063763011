@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "variables";

.container {
	@include media-breakpoint-down(sm) {
		.form-group {
			padding-left: 0;
			padding-right: 0;
		}
	}
}

.card {
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	border: none;
	border-radius: 2px;
	.card-header {
		@include media-breakpoint-down(sm) {
			margin: 0;
		}
		&:first-child {
			border-radius: $standard-border-radius;
		}
		&__dark {
			background-color: $background-dark-gray;
			color: white;
		}
	}

	margin-bottom: 1rem;
}

.row {
	margin-left: 0;
	margin-right: 0;
}

.table {
	padding: 0 0.5rem;
	@include media-breakpoint-down(sm) {
		padding-top: 1rem;
		padding-bottom: 1rem;
	}
	.table-column {
		&-header {
			color: $weak-gray-color-1;
			text-transform: uppercase;
			padding: 0.5rem 1rem;
			font-size: 9pt;
		}

		&-cell {
			padding-left: 1rem;
			justify-content: center;
			align-items: center;
			@include media-breakpoint-down(sm) {
				align-items: stretch;
			}
		}
		&-cell-content {
			display: flex;
			flex-direction: column;
			@include media-breakpoint-down(sm) {
				justify-content: space-between;
			}

			&-label {
				@include media-breakpoint-up(sm) {
					display: none;
				}
				text-transform: uppercase;
				color: $weak-gray-color-1;
				text-align: center;
			}
			&-value {
				font-size: $mobile-middle-font-size;
			}
		}
	}

	&__dark {
		background-color: $background-dark-gray;

		.table-column {
			&-header {
				color: white;
			}
		}
	}
}

form {
	textarea,
	input {
		border-radius: $standard-border-radius;
	}
}

.content {
	.header {
		.container {
			padding: 2rem 0.5rem;
		}
	}
}

.btn {
	border-radius: 2px;
	text-transform: uppercase;
	padding: 10px 20px;
	font-size: 0.75rem;
	line-height: 0.75rem;
	font-weight: 500;

	@include media-breakpoint-down(sm) {
		font-size: 1.5rem;
		padding: 2rem;
	}
	&.btn-primary {
		background: #ea373a;
		color: #fff;
		border: none;
	}
	&.btn-round {
		border-radius: 50%;
		padding: 0;
		min-width: 36px;
		min-height: 36px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	&.btn-secondary {
		color: $font-color-dark-grey;
		background-color: #fff;
		border: none;
		border: 1px solid grey;
	}
	&.btn-tertiary {
		background-color: white;
		border: 1px solid #5D5C5D;
	}
	&.btn-icon {
		.icon {
			margin-right: 12px;
		}
	}
	&.btn-light {
		background: rgba($weak-gray-color-1, 0.1);
		border-color: rgba($weak-gray-color-1, 0.1);
		color: $weak-gray-color-1;
	}

}
.btn.btn-wide {
	padding: 20px 30px;
}
.btn.btn-icon {
	img {
		margin-right: 12px;
	}
}
.btn.btn-inactive {
	background: #C9C9C9;
	color: white;
}
.btn.btn-active {
	background: $blue-active;
	color: white;
}

.icon {
    color: $weak-gray-color-1;

    &.active {
        color: $blue-active;
    }
}
