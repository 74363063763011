@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "variables";
@import "~reactjs-popup/dist/index.css";
@import "~react-toggle/style.css";

html {
  background: #ffffff;
  margin: 0;
  padding: 0;
  font-size: 20px;
  color: #2a2e37;
}
@media (max-width: 767px) {
  html {
    font-size: 15px;
  }
}
body {
  margin: 0;
  padding: 0;
  font-size: 0.75rem;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}

h2 {
  font-size: 18px;
  font-weight: normal;
}

h5,
h4,
h3,
h2,
h1 {
  font-family: "Roboto", sans-serif;
}
h4 > span,
h2 > span,
h1 > span {
  color: #ea373a;
}
h1 {
  font-size: 2rem;
}
h1 > span {
  font-size: 4rem;
}
h4 {
  font-size: 1.1rem;
}
h4 > span {
  font-size: 2.5rem;
}
.container {
  width: 100%;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.page {
  padding: 12px;
}
.full-page {
  height: calc(100vh - 90px - 110px);
  overflow: hidden;
}
.full-height {
  height: 100%;
  max-height: 100%;
  min-height: 100%;
}
#top {
  padding-top: 10px;
  padding-bottom: 10px;
  background: #ffffff;
}
#top nav {
  display: flex;
  justify-content: space-between;
}
#top nav .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,<svg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'><path stroke='rgba(234, 55, 58, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/></svg>");
}
#top nav #logo {
  flex: 1 1 auto;
}

#top nav ul li {
  display: block;
}
#top nav ul li a {
  display: block;
  color: #2a2e37;
}
header {
}
header .slider {
}
header .slider ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
header .slider ul li {
  margin: 0;
  padding: 80px 0;
  background-position: center;
  background-size: cover;
}

header .slider ul li .text {
  font-weight: 500;
  font-size: 0.85rem;
}
header .slider ul li form {
  display: block;
  background: #ffffff;
  color: #22252c;
  margin-top: 15px;
}
header .slider ul li form header {
  background: #22252c;
  color: #fff;
  padding: 40px;
  text-align: center;
  text-transform: uppercase;
  font-size: 0.9rem;
  line-height: 0.9rem;
}
header .slider ul li form .form {
  padding: 25px 35px;
}
header .slider ul li form .form h4 {
  text-align: center;
}
header .slider ul li form .form .help {
  margin-top: 20px;
  text-align: center;
  color: #a2a2a2;
}
header .slider ul li form .form .btn {
  width: 100%;
}
.akt-form-control {
  background-color: #f7f7f7 !important;
  border: solid 1px #e2e3e5;
  font-size: 0.9rem;
  margin-bottom: 0.9rem;
  border-radius: 2px;
  padding: 15px;
}
.akt-form-control[name="username"] {
  background-image: url("assets/imgs/field-name.jpg");
  background-position: 10px 50%;
  background-repeat: no-repeat;
  padding-left: 45px;
}
.akt-form-control[name="password"] {
  background-image: url("assets/imgs/field-pass.jpg");
  background-position: 10px 50%;
  background-repeat: no-repeat;
  padding-left: 45px;
}
.competition {
  background: #fff;
  padding-top: 100px;
  padding-bottom: 100px;

  header {
    text-align: center;
    margin-bottom: 50px;
  }
}

.block {
  padding-left: 36px;
  padding-right: 36px;
  padding-bottom: 36px;
}

.competition .items ul li {
  margin: 0;
  flex: 1 1 auto;
  display: flex;
}

.underlined {
  border-bottom: 1pt solid $weak-gray-color-1;
}

// Small devices (landscape phones, 576px and up)
@media (max-width: 576px) {
  body {
    font-size: 1rem;
    font-weight: 400;
  }
}
.clickable {
  cursor: pointer;
}

input,
textarea {
  background-color: white;
  border: none;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.three-dots {
  text-align: end;
  cursor: pointer;

  &:after {
    content: "\2807";
    font-size: 24px;
    color: $weak-gray-color;
  }
}

.flex-centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.y-scrollable {
  overflow-y: scroll;
}

@import "components";
