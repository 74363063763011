@import "../../variables";

.message-link {
    &:hover {
        text-decoration: none;
    }
}

.message-row {
    background-color: $background-dark;
    margin-bottom: 6px;
    position: relative;
    display: flex;
    padding: 12px;
    border-radius: $standard-border-radius;
    font-size: 14px;
    color: #5d5c5d;

    &.selected {
        background-color: $blue-light;
    }

    .sender {
        min-width: 100px;
        width: 100px;
        margin-right: 12px;
        padding-left: 12px;
    }

    .subject {
        min-width: 100px;
        max-width: 100px;
    }
    .main {
        width: 100%;
        padding-left: 12px;
        .text {
            display: flex;
            justify-content: space-between;
            .tools {
                min-width: 100px;
                justify-content: center;
            }

            .snippet {
                padding-left: 12px;
                padding-right: 12px;
                flex-grow: 1;
                max-width: 300px;
            }

            .tags {
                min-width: 100px;
                max-width: 100px;
                justify-content: center;
                position: relative;

                &-edit {
                    position: absolute;
                    right: -12px;
                }

                input {
                    max-width: 100px;
                    height: 28px;
                    background-color: white;
                    border: none;
                    text-align: center;
                }
            }
        }
    }

    .important {
        display: flex;
        align-items: center;
        font-weight: bold;
        align-items: flex-start;
        color: #292929;
    }

    .date {
        padding-left: 12px;
        width: 100px;
    }
}

.note-form {
    &-content {
        background-color: #fcf4da;
        width: 300px;
        height: 350px;
        padding: 12px;
    }

    &-body {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        textarea {
            width: 100%;
            height: 200px;
            margin-bottom: 12px;
        }
    }
}
