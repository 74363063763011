@import "../../variables";

.splitable-table {
    display: grid;
    &.direction-column {
        row-gap: $gutter-small;        
        &.template-1-to-1 {
            grid-template-rows: 50% 50%;
        }
        &.template-2-to-10 {
            grid-template-rows: 2fr 10fr;
        }
        &.template-auto {
            grid-template-rows: auto auto;
        }
        &.template-auto-to-1 {
            grid-template-rows: auto 1fr;
        }
    }
    &.direction-row {
        column-gap: $gutter-small;
        &.template-1-to-1 {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
    }
}
