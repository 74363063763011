@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "../../variables";

.navbar-brand {
  width: 50px;
}

.navigation {
  height: 90px;
  #basic-navbar-nav {
    justify-content: flex-end;
  }
}

.nav-link {
  &.active {
    color: $font-color-red !important;
  }

  &-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include media-breakpoint-down(md) {
      flex-direction: row;
      justify-content: start;

      img {
        margin-right: 1rem;
      }
    }

    img {
      height: 34px;
      width: 34px;
      margin-bottom: 8px;
    }
  }
}

@include media-breakpoint-up(lg) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 6px;
    padding-left: 6px;
    margin-left: 1rem;
    margin-right: 1em;
  }

  .nav-link.active {
    border-bottom: 4px solid $background-red;
  }
}
