@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "../../variables";
.mail {
    background-color: white;
    //border: 1px solid #d1d1d1;
    display: flex;
    flex-direction: column;
    padding: 0;
    height: 100%;
    border-radius: $standard-border-radius;

    &-header {
        padding: 12px;

        &-top {
            display: flex;
            flex-direction: column;

            .info {
                display: flex;
                font-size: 18px;
                flex-grow: 1;

                .btn {
                    margin-right: $gutter-small;
                }

                .subject {
                    display: flex;
                    align-items: center;
                }
            }

            .meta {
                display: flex;
                justify-content: space-between;
            }

            .sender {
                text-transform: lowercase;
                font-weight: bold;
                font-size: 12px;
                margin-left: 48px;
            }
        }

        .attachments {
            padding-top: 20px;
        }
    }

    &-body {
        padding: 12px;
        margin-bottom: 12px;
        height: 100%;

        .btn-icon {
            color: #5d5c5d;
            .icon {
                color: #f4bf26;
            }
        }

        .note {
            background-color: #fcf4da;
            border-radius: $standard-border-radius;
            padding: 12px;
            white-space:  pre-wrap;
        }

        .note-form-body {
            background-color: #fcf4da;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 12px;

            textarea {
                width: 100%;
                height: 200px;
                margin-bottom: 12px;
            }
        }
    }

        .vertical {
            flex-direction: column;
            .notes {
                order: -1;
            }
        }
        iframe {
            width: 100%;
            height: 100%;
            border: none;
        }

}
