@import "../../../variables";

.table-body {
    //background-color: $background-dark;
    padding: $gutter-small;
    margin-left: -$gutter-small;
    margin-right: -$gutter-small;
    border-bottom-left-radius: $standard-border-radius;
    border-bottom-right-radius: $standard-border-radius;
    border: $border;
    display: flex;
    flex-direction: column;

    border-top: 0;
    flex-grow: 1;

}