@import "../../variables";

.shared-page {

    &-table {
        overflow-y: scroll;
    }

    &-header {
        display: flex;

        .share {
            margin-left: 12px;
        }
    }
}