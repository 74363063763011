@import "../../../variables";
.message-group-header {
    display: flex;

    .icon {
        margin-right: $gutter-small;
    }

    &-group {
        padding: $gutter-half $gutter-small;
        color: $weak-gray-color-1;
        border-radius: $half-border-radius;

        &.active {
            background-color: $blue-light;
            color: $blue-active;
        }
    }
}
