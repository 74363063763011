@import "../../../variables";

.table-header {
    height: 48px;
    padding: $gutter-small;
    padding: $gutter-medium;
    margin-left: -$gutter-small;
    margin-right: -$gutter-small;
    border-top-left-radius: $standard-border-radius;
    border-top-right-radius: $standard-border-radius;
    border: $border;
    display: flex;
    align-items: center;

    &-content {
        flex-grow: 1;
    }


    label {
        display: flex;
        align-items: center;
    }

    .three-dots {
        justify-self: flex-end;
    }
}

.widget-selector-content {
    z-index: 10000;
    .menu {
        &-item {

            padding: $gutter-half $gutter-small;
            cursor: pointer;
        }
    }
}